
import { Component, Vue } from "vue-property-decorator";
import { HealthProductService, UserService, WechatService } from "src/services";
import { Toast, Dialog } from "vant";
import { Pay, tools } from "src/utils";
// import sensors from "src/sensors";
import udialog from "src/components/dialog";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    udialog,
  },
})
export default class healthInsuranceDetail extends Vue {
  /**
   * 数据模型
   * @private
   * @returns any
   */
  protected dataModel: any = {
    bannerPicts: "",
    bannerPictsUrls: [],
    id: null,
    infoPicts: "",
    infoPictsUrls: [],
    introduce: "",
    isNeedAppointment: false,
    mainPict: "",
    mainPictUrl: "",
    marketPrice: null,
    name: "",
    price: null,
    remark: "",
    supplierId: null,
    supplierName: "",
    productsLinkType: null,
    appletAppId: "",
    jumpMiddlePageImageUrls: [],
    jumpMiddlePageImages: [],
    isPut: true,
  };

  /**
   * 判断是否数微信环境
   * @private
   * @returns boolean
   */
  private isWechat(): boolean {
    let ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf("micromessenger") > -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (this.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private wx_launch(info): void {
    if (!this.isWechat()) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;" env-version="release" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  private reportContent: string = "";

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected mounted() {
    this.init();
    this.reportContent = `
            <button class="test-btn"></button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 69px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            `;
  }

  /**
   * 商品详情id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 是否显示弹框
   * @private
   * @returns boolean
   */
  private isShowDialog: boolean = false;

  /**
   * 弹框内容
   * @private
   * @returns boolean
   */
  private dialogContent: string = "请联系客服：400-865-0985";

  /**
   * 是否详情
   * @private
   * @returns boolean
   */
  private get isDetail(): boolean {
    return (this.$route.query && (this.$route.query.isDetail as any)) || false;
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getHealthProductDetail(this.id);
    // sensors.track('GJ_tg_details_exposure');
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let appid = localStorage.getItem("appid");
    if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
      location.assign(`/${appid}${to.fullPath}`);
    } else {
      next();
    }
  }

  /**
   * 点击购买
   * @protected
   * @returns void
   */
  protected onBuy() {
    // 跳转小程序
    let actionUrl = this.dataModel.productsLink.replace(/\(\(userId\)\)/g, this.userInfo && this.userInfo.genUserId);
    if (this.dataModel.productsLinkType == 1) {
      let cancelParams = {
        appid: this.dataModel.h5AppletAppId,
        eleId: "toDetail", // 元素id
        url: actionUrl,
        content: this.reportContent, // 自定义的html内容
      };

      this.wx_launch(cancelParams);
    } else if (this.dataModel.productsLinkType == 2) {
      // 外链
      // 判断是否有中转页
      if (this.dataModel.jumpMiddlePageImageUrls && this.dataModel.jumpMiddlePageImageUrls.length > 0) {
        this.$router.push({ name: "healthTransfer", query: { jumpMiddlePageImageUrls: this.dataModel.jumpMiddlePageImageUrls.join(",") } });
      } else {
        this.$router.push({ name: "healthInsurance-buy" });
      }

      // 链接保存
      localStorage.setItem("productsLink", actionUrl);
    } else {
      this.isShowDialog = true;
    }
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 获取健康产品详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getHealthProductDetail(id: string): Promise<void> {
    try {
      let { content: result } = await HealthProductService.instance.getHealthProductDetail(id);
      if (result.data) {
        this.dataModel = result.data;
        this.shareData.name = this.dataModel.name;
        this.shareData.price = this.dataModel.price;
        this.shareData.marketPrice = this.dataModel.marketPrice;
        this.shareData.qrCode = window.location.href;
        if (this.dataModel.infoPictsUrls && this.dataModel.infoPictsUrls.length > 0) {
          this.shareData.bannerUrl = this.dataModel.infoPictsUrls[0] || "";
          this.getUrl2base64(this.dataModel.infoPictsUrls[0]);
        }

        let actionUrl = this.dataModel.productsLink.replace(/\(\(userId\)\)/g, this.userInfo && this.userInfo.userId);

        let cancelParams = {
          appid: this.dataModel.h5AppletAppId,
          eleId: "toDetail", // 元素id
          url: actionUrl,
          content: this.reportContent, // 自定义的html内容
        };

        this.wx_launch(cancelParams);
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 是否显示分享弹出框
   * @private
   * @returns boolean
   */
  private showSharePopup: boolean = false;

  /**
   * 分享弹出框数据
   * @private
   * @returns any
   */
  private shareData: any = {
    name: "",
    price: null,
    marketPrice: null,
    qrCode: "",
    posterUrl: "",
    bannerUrl: "",
  };

  /**
   * 获取url获取base64
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getUrl2base64(url: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.url2base64(url);
      if (result.data) {
        this.shareData.posterUrl = "data:image/png;base64," + result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
