
import { Component, Vue } from "vue-property-decorator";
import { HealthProductService, UserService, WechatService } from "src/services";
import { Toast, Dialog } from "vant";
import { Pay, tools } from "src/utils";
import router from "src/router";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component
export default class healthInsuranceDetail extends Vue {
  private jumpMiddlePageImageUrls: string = "";

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected mounted() {
    this.jumpMiddlePageImageUrls = (this.$route.query && (this.$route.query.jumpMiddlePageImageUrls as string)) || "";

    setTimeout(() => {
      this.$router.push({ name: "healthInsurance-buy" });
    }, 3000);
  }
}
